<template>
  <page-container>
    <work-in-progress></work-in-progress>
  </page-container>
</template>

<script>
export default {
  data: () => ({}),
  computed: {},
  components: {
    PageContainer: () => import('@/components/reusable/PageContainer')
  }
}
</script>
